/* API request complete path */
export const API_URL = process.env.REACT_APP_HOST+'/';

/* Website Url */
export const WEBSITE_URL = process.env.REACT_APP_HOST+'/';

/* Key for API authentication */
export const API_HEADER_AUTH_KEY = process.env.REACT_APP_API_HEADER_AUTH_KEY;

/* Images path */
export const WEBSITE_JS_URL				= 	API_URL+"frontend/js/";
export const WEBSITE_PUBLIC_IMG_URL		= 	API_URL+"frontend/images/";
export const WEBSITE_SETTINGS_IMG_URL	= 	API_URL+"frontend/uploads/settings/";
export const WEBSITE_IMG_URL 			= 	WEBSITE_URL+"images/";

/* Languages configurations */
export const ENGLISH_LANGUAGE_MONGO_ID	= "5a3a13238481824b077b23ca";
export const ENGLISH_LANGUAGE_CODE		= "en";

/* Default language configurations */
export const DEFAULT_LANGUAGE_CODE		= ENGLISH_LANGUAGE_CODE;
export const DEFAULT_LANGUAGE_ID		= ENGLISH_LANGUAGE_MONGO_ID;

/* Default country code */
export const  DEFAULT_COUNTRY_CODE	=	"+91";

export const LANGUAGES = [
	{"id" : ENGLISH_LANGUAGE_MONGO_ID, "name" : "English"}
];

/* Supported languages list */
export const LANGUAGE_CODES = {};
LANGUAGE_CODES[ENGLISH_LANGUAGE_MONGO_ID]	=	ENGLISH_LANGUAGE_CODE;

/** Mongodb Sorting types */
export const SORT_ASC = 1;
export const SORT_DESC = -1;

/** For currency symbol */
export const CURRENCY_SYMBOL = 'INR';
export const CURRENCY_CODE = '₹';

/* Supported languages list at product add and edit section */
export const LANGUAGE_SHORT_CODES_WITH_TITLE = [
	{"id" : ENGLISH_LANGUAGE_MONGO_ID, "name" : "English", "short_code"	: ENGLISH_LANGUAGE_CODE},
];

/** Date format **/
export const AM_PM_FORMAT_WITH_DATE		=	"YYYY/MM/DD hh:mm A";
export const ORDER_FORMAT_WITH_DATE		=	"DD MMMM YYYY";
export const BLOG_FORMAT_WITH_DATE		=	"DD MMMM, YYYY";

/** API status */
export const STATUS_SUCCESS = "success";
export const STATUS_ERROR 	= "error";

export const ROUND_PRECISION 	 	= 2;
export const HOME_CATEGORY_LIMIT 	= 3;
export const HOME_PRODUCT_LIMIT  	= 3;
export const PRODUCT_LISTING_LIMIT  = 20;
export const SEARCH_TERM_PRODUCT_LISTING_LIMIT  = 3;
export const BLOG_LISTING_LIMIT 	= 20;
export const LATEST_BLOG_LIMIT 		= 1;
export const FEATURED_BLOG_LIMIT 	= 3;
export const HEADER_PRODUCT_LIMIT  	= 6;

/** Sort type */
export const HIGH_TO_LOW = "high_to_low";
export const LOW_TO_HIGH = "low_to_high";
export const NEW_ARRIVAL = "new_arrival";
export const DISCOUNT 	= "discount";

export const SIZE_ATTRIBUTE 	= "size";
export const COLOR_ATTRIBUTE 	= "color";

export const SIMPLE_PRODUCT 	=	"simple";
export const CONFIGURABLE_TYPE	= 	"configurable";

export const ACTIVE		= 	1;
export const NOT_READ	= 	0;

export const FORGOT_PASSWORD = "forgot_password";
export const RESET_PASSWORD = "reset_password";

export const MAX_CART_COUNTER = 99;
export const MAX_WISH_LIST_COUNTER = 99;


export const NOTIFICATION_ORDER_OUT_OF_DELIVERY =	3;
export const NOTIFICATION_ORDER_DELIVERED 		=	4;
export const NOTIFICATION_ORDER_CANCELLED 		=	5;

/** Order Status */
export const PAYMENT_PENDING		=	"payment_pending";
export const PAYMENT_FAILED			=	"payment_failed";
export const ORDER_PLACED 			=	"placed";
export const ORDER_OUT_OF_DELIVERY	=	"out_of_delivery";
export const ORDER_DELIVERED		=	"delivered";
export const ORDER_CANCELLED		=	"cancelled";
export const ORDER_RETURNED 		=	"returned";

/** Order filter type */
export const NOT_YET_SHIPPED		=	"not_yet_shipped";
export const CANCELLED				=	"cancelled";
export const RETURNED				=	"returned";

/*Payment Order Status*/
export const ORDER_ACTIVE		=	"ACTIVE";
export const ORDER_PAID 		=	"PAID"; 

/** Filter order time period */
export const LAST_30_DAYS		=	"30days";
export const LAST_3_MONTHS		=	"3months";
export const MAX_PERVIOUS_MONTHS=	3;

/**For quantity dropdown limit */
export const QUANTITY_MAX_LIMIT  = 10;

export const CATEGORY_DISPLAY_LIMIT  = 10;

export const PRODUCT_GENDER_MALE  	=	"male";
export const PRODUCT_GENDER_FEMALE  = 	"female";

export const LATEST_CAREER_LIMIT = 5;

export const OVERSIZED_TSHIRT 	=	"oversized";
export const PERSONAL_TOUCH_CHARACTER_LIMIT = 15;
export const CREATION_TYPE_CUSTOM = "custom";

export const CASH 	=	"cash";
export const ONLINE	=	"online";