import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import Cookies from 'universal-cookie';
import { Context }  from './../Auth.Context';

const Layout = () => {
	const cookies = new Cookies();
	const [userDetails, setUserDetails] = useState(cookies.get('user_details') || {});
	return (
		<>
			<Context.Provider value={[userDetails, setUserDetails]}>
				<Outlet />
			</Context.Provider>
		</>
	)
};
export default Layout;
