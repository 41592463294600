import React, { useContext } from 'react';
import {Navigate, Outlet } from 'react-router-dom';
import { Context }  from './../Auth.Context';

const PublicOnlyRoute = () => {
	const [userDetails,] = 	useContext(Context);

	if(!userDetails || !userDetails._id){
		return <Outlet />
	}
	else if (userDetails && userDetails._id) return <Navigate to="/customer" />;
	else return null;
};
export default PublicOnlyRoute;
